
import Pool from "../../../lib/UserPool.js";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";

export const getSession = async () =>
    await new Promise((resolve, reject) => {
        const user = Pool.getCurrentUser();
        if (user) {
            user.getSession(async (err, session) => {
                if (err) {
                    reject();
                } else {
                    const attributes = await new Promise((resolve, reject) => {
                        user.getUserAttributes((err, attributes) => {
                            if (err) {
                                reject(err);
                            } else {
                                const results = {};

                                for (let attribute of attributes) {
                                    const { Name, Value } = attribute;
                                    results[Name] = Value;
                                }

                                resolve(results);
                            }
                        });
                    });

                    resolve({
                        user,
                        ...session,
                        ...attributes,
                    });
                }
            });
        } else {
            reject();
        }
    });


export const authenticate = async (Username, Password) =>
    await new Promise((resolve, reject) => {
        const user = new CognitoUser({ Username, Pool });
        const authDetails = new AuthenticationDetails({ Username, Password });

        user.authenticateUser(authDetails, {
            onSuccess: (data) => {
                resolve(data);
            },

            onFailure: (err) => {
                reject(err);
            },

            newPasswordRequired: (data) => {
                resolve({ status: "setpassword" });
            },
        });
    });


  export  const setp = async (Username, Password, NewPassword) =>
    await new Promise((resolve, reject) => {
        const user = new CognitoUser({ Username, Pool });
        const authDetails = new AuthenticationDetails({ Username, Password });
  
        user.authenticateUser(authDetails, {
            onSuccess: (data) => {
                resolve({ status: "failed", error: "This account already exists." });
            },
  
            onFailure: (err) => {
                resolve({ status: "failed", error: "Failed to Authenticate." });
            },
  
            newPasswordRequired: (data) => {
  
                const d = { email: data.email, name: data.name };
  
                user.completeNewPasswordChallenge(NewPassword, d, {
                    onSuccess: (result) => {
                        resolve({ status: "success" });
                    },
  
                    onFailure: (err) => {
                        resolve({ status: "failed", error: "Failed to Authenticate." });
                    },
                });
            },
        });
    });